import { normalize } from 'normalizr'

export default {

  async list ({
    commit,
    state,
    getters,
    dispatch
  }) {
    const params = getters.params

    const response = await this.$repositories[state.type].index(params)

    if (response) {
      const { entities } = normalize(response.data, [
        this.$relationships[state.type]
      ])
      commit('MERGE', entities[state.type])
    }
  },

  async subList ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payload) {
    const response = await this.$repositories[payload.type].subIndex(payload.id)

    dispatch(`${payload.child}/subListMerge`, response.data, {
      root: true
    })
  },
  subListMerge ({
    commit,
    state,
    getters,
    dispatch
  }, payload) {
    const { entities } = normalize(payload, [this.$relationships[state.type]])
    commit('MERGE', entities[state.type])
  },

  merge ({
    commit,
    state,
    dispatch
  }, payload) {
    commit('MERGE', payload)
  },

  async create ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payload) {
    let results = {}
    results = await this.$repositories[state.type].create(payload)

    if (results) {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )

      commit('MERGE', entities[state.type])
      dispatch('snackbar/showMessage', {
        content: {
          message: 'Success!',
          status: 201
        },
        color: 'success'
      }, {
        root: true
      })
      return results
    }
  },

  async subCreate ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, payload) {
    const repositoryPath = payload.repositoryPath
    const id = payload.id
    const resource = payload.resource

    const results = await this.$repositories[repositoryPath].subCreate(id, resource)

    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )

    commit('MERGE', entities[state.type])

    return results
  },

  async show ({
    commit,
    state,
    getters,
    dispatch,
    rootState
  }, id) {
    // This is needed to include relations
    const params = getters.showParams
    console.log('params', params)

    const results = await this.$repositories[state.type].show(id, params)

    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    commit('MERGE', entities[state.type])
  },
  async update (
    {
      commit,
      state,
      getters,
      dispatch,
      rootState
    },
    {
      id,
      payload
    }
  ) {
    const results = await this.$repositories[state.type].update({
      id,
      payload
    })
    for (const relation of state.relations) {
      dispatch(`${relation.storename}/list`, null, { root: true })
    }

    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    commit('MERGE', entities[state.type])
    dispatch('snackbar/showMessage', {
      content: {
        message: 'Success!',
        status: 200
      },
      color: 'success'
    }, {
      root: true
    })
    return true
  },
  async bulkUpdate (
    { commit, state, getters, dispatch, rootState },
    payloadPerId
  ) {
    // payloadPerId.waterYear = rootState.system.waterYear

    const response = await this.$repositories[state.type].bulkUpdate(
      payloadPerId
    )
    if (Object.hasOwn(response, 'error')) {
      dispatch('snackbar/showMessage', {
        content: {
          message: 'Error!',
          status: 200
        },
        color: 'error'
      }, {
        root: true
      })
      return true
    }

    let data = response.data

    if (!Array.isArray(data)) {
      data = Object.values(data)
    }

    const { entities } = normalize(data, [
      this.$relationships[state.type]
    ])
    commit('MERGE', entities[state.type])

    return true
  },

  async delete (
    {
      commit,
      state,
      getters,
      dispatch,
      rootState
    },
    id
  ) {
    await this.$repositories[state.type].delete(id)

    commit('REMOVE', id)
    dispatch('snackbar/showMessage', {
      content: {
        message: 'Success!',
        status: 200
      },
      color: 'success'
    }, {
      root: true
    })
    return true
  },
  UpdateBound ({
    commit,
    state
  }, payload) {
    const schema = Object.values(payload)
    const defaultBound = state.bound
    const p = {}
    schema.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (child.type === 'checkbox') {
            p[child.field] = defaultBound[child.field] ? defaultBound[child.field] : 0
          } else {
            p[child.field] = defaultBound[child.field] ? defaultBound[child.field] : ''
          }
        })
      } else if (item.type === 'checkbox') {
        p[item.field] = defaultBound[item.field] ? defaultBound[item.field] : 0
      } else {
        p[item.field] = defaultBound[item.field] ? defaultBound[item.field] : ''
      }
    })
    commit('updateBound', p)
  },

  async detach (
    {
      commit,
      state,
      getters,
      dispatch
    },
    {
      parentId,
      childId,
      path,
      apiPath
    }
  ) {
    const results = await this.$repositories[apiPath].subDelete(parentId, childId)

    // returns parent
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )
    // commit("MERGE", entities[state.type]);
    Object.entries(entities).forEach(([key, value]) =>
      dispatch(`${key}/merge`, value, {
        root: true
      })
    )
  }

}
