import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  isStale: true,
  latest_fetch: '',
  type: 'fileGeneral',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  modelLabels: {
    single: 'FileGeneral',
    plural: 'FileGeneral'
  },
  filterOverrides: [],
  aliasField: 'id',
  relations: [
    {
      name: 'file',
      translate: 'file',
      label: 'File',
      value: 'file',
      storename: 'files',
      export: false
    }
  ],
  all: {
  },
  active: false,
  searchFields: ['parent'],
  bound: {
    name: ''
  },
  filter: {},
  quickFilters: [],
  stub: {},
  include: ''
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
