import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  type: 'filters',
  effectedByYear: false,
  yearFields: [],
  all: {},
  relations: [],
  reference: {},
  active: false,
  page: 1,
  modelLabels: {
    single: 'Filter',
    plural: 'Filters'
  },
  aliasField: 'filter_name',
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: false,
    sortBy: 'name',
    page: 1,
    descending: true
  },
  total: 0,
  print: false,
  export: false,
  search: null,
  bound: {},
  filter: {},
  stub: {},
  include: '',
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
