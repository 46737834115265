import { schema } from 'normalizr'

export default (ctx, inject) => {
  const relationships = {
    adjustments: new schema.Entity('adjustments'),
    animals: new schema.Entity('animals'),
    animalTypes: new schema.Entity('animalTypes'),
    animalTypeRegions: new schema.Entity('animalTypeRegions'),
    announcements: new schema.Entity('announcements'),
    extras: new schema.Entity('extras'),
    files: new schema.Entity('files'),
    fileGeneral: new schema.Entity('fileGeneral'),
    flags: new schema.Entity('flags'),
    filters: new schema.Entity('filters'),
    sectionSchemas: new schema.Entity('sectionSchemas'),
    mounts: new schema.Entity('mounts'),
    mountTypes: new schema.Entity('mountTypes'),
    notifications: new schema.Entity('notifications'),
    regions: new schema.Entity('regions'),
    settings: new schema.Entity('settings'),
    tags: new schema.Entity('tags'),
    trophyrooms: new schema.Entity('trophyrooms'),
    users: new schema.Entity('users')
  }

  relationships.adjustments.define({})
  relationships.animals.define({})
  relationships.animalTypes.define({})
  relationships.animalTypeRegions.define({})
  relationships.announcements.define({})
  relationships.extras.define({})
  relationships.files.define({})
  relationships.fileGeneral.define({})
  relationships.flags.define({})
  relationships.filters.define({})
  relationships.sectionSchemas.define({})
  relationships.mounts.define({})
  relationships.mountTypes.define({})
  relationships.notifications.define({})
  relationships.regions.define({})
  relationships.settings.define({})
  relationships.users.define({})
  relationships.tags.define({})
  relationships.trophyrooms.define({})

  inject('relationships', relationships)
}
