import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  isStale: true,
  latest_fetch: '',
  type: 'regions',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  inlineEdit: true,
  modelLabels: {
    single: 'Region',
    plural: 'Regions'
  },
  filterOverrides: [],
  aliasField: 'name',
  relations: [
  ],
  all: {},
  active: false,
  searchFields: ['name'],
  bound: {
    name: ''
  },
  filter: {},
  quickFilters: [],
  stub: {},
  include: ''
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
