import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  isStale: true,
  latest_fetch: '',
  type: 'mounts',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  inlineEdit: true,
  modelLabels: {
    single: 'Mount',
    plural: 'Mounts'
  },
  filterOverrides: [],
  aliasField: 'name',
  relations: [
    {
      name: 'animals',
      translate: 'animals',
      label: 'Animal',
      value: 'animals',
      storename: 'animals',
      export: false
    },
    {
      name: 'mountTypes',
      translate: 'mountTypes',
      label: 'Mount Types',
      value: 'mountTypes',
      storename: 'mountTypes',
      export: false
    },
    {
      name: 'extras',
      translate: 'extras',
      label: 'Extras',
      value: 'extras',
      storename: 'extras',
      export: false
    },
    {
      name: 'adjustments',
      translate: 'adjustments',
      label: 'Adjustments',
      value: 'adjustments',
      storename: 'adjustments',
      export: false
    },
    {
      name: 'files',
      translate: 'files',
      label: 'files',
      value: 'files',
      storename: 'files',
      export: false
    }
  ],
  all: {},
  active: false,
  searchFields: ['name'],
  bound: {
    name: ''
  },
  filter: {},
  quickFilters: [],
  stub: { mountTypes: {}, escalator_type: 'fixed' },
  include: ''
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
