import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'

export const state = () => ({
  isStale: true,
  latest_fetch: '',
  type: 'mountTypes',
  pageLayout: 'LayoutStructureTable',
  canEdit: false,
  canView: false,
  inlineEdit: true,
  modelLabels: {
    single: 'Mount Type',
    plural: 'Mount Types'
  },
  filterOverrides: [],
  aliasField: 'name',
  relations: [
    {
      name: 'mounts',
      translate: 'mounts',
      label: 'Mounts',
      value: 'mounts',
      storename: 'mounts',
      export: false
    }
  ],
  all: {},
  active: false,
  searchFields: ['name'],
  bound: {
    name: ''
  },
  filter: {},
  quickFilters: [],
  stub: {},
  include: ''
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
