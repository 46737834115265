import Errors from '@/Errors'

export default ({ $axios, redirect, app, store, $bugsnag }, inject) => {
  $axios.onError((error) => {
    if (error.response === undefined) {
      // Display a flash notification
      console.log('Error:', error)
      throw error
    }

    // Handle other types of errors (e.g., redirect to login on 401 errors)
    console.log('Error:', error)
    const errorResponse = new Errors().setAndParse(error)
    console.log(errorResponse)
    store.commit('snackbar/showMessage', { content: errorResponse, color: 'error' })

    $bugsnag.notify(errorResponse.message)
    throw error
  })
}
