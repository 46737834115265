import Vue from 'vue'
import { format, fromUnixTime } from 'date-fns'

Vue.filter('formatPhone', function (v) {
  if (!v) {
    return ''
  }
  const s2 = ('' + v).replace(/\D/g, '')
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3]
})

Vue.filter('formatUnixDate', function (v) {
  if (!v) {
    return '--'
  }
  const date = fromUnixTime(v)
  return format(date, 'MMM DD, YYYY')
})

Vue.filter('formatDate', function (value) {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return format(date, 'MMM DD, YYYY')
})

Vue.filter('formatDateNoYear', function (value) {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return format(date, 'MMM DD')
})

Vue.filter('formatDateTime', function (value) {
  if (!value) {
    return '--'
  }
  const date = new Date(value)
  return format(date, 'MMM DD, YYYY hh:mm A')
})

// Vue.filter('formatLocalDate', function (value) {
//   if (!value) {
//     return '--'
//   }
//   const date = new Date(value)
//   return moment.utc(date).local().format('MMM DD, YYYY')
// })
// Vue.filter('formatLocalDateTime', function (value) {
//   if (!value) {
//     return '--'
//   }
//   const date = new Date(value)
//   return moment.utc(date).local().format('MMM DD, YYYY hh:mm A')
// })

Vue.filter('formatTime', function (value) {
  return value
})

Vue.filter('formatHours', function (value) {
  return value.toFixed(2)
})

Vue.filter('formatToFixed', function (value) {
  return Number(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('formatMoney', function (value) {
  if (!value) {
    return '--'
  } else {
    return (
      '$' + (value / 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    )
  }
})

Vue.filter('pluralize', function (n, w) {
  if (n === 1) {
    return w
  } else {
    return w + 's'
  }
})

Vue.filter('formatTime', function (value) {
  const date = new Date(value)
  return format(date, 'h:mm A')
})

Vue.filter('formatNotification', function (value) {
  return value
})

Vue.filter('titleCase', function (value) {
  let str = value.toLowerCase()
  str = str.split(' ')

  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
})
