import createRepository from '~/api/Repository.js'

export default (ctx, inject) => {
  const repositoryWithAxios = createRepository(ctx.$axios)
  const repositories = {
    adjustments: repositoryWithAxios('adjustments'),
    animals: repositoryWithAxios('animals'),
    animalTypes: repositoryWithAxios('animalTypes'),
    animalTypeRegions: repositoryWithAxios('animalTypeRegions'),
    announcements: repositoryWithAxios('announcements'),
    extras: repositoryWithAxios('extras'),
    files: repositoryWithAxios('files'),
    fileGeneral: repositoryWithAxios('fileGeneral'),
    flags: repositoryWithAxios('flags'),
    filters: repositoryWithAxios('filters'),
    sectionSchemas: repositoryWithAxios('sectionSchemas'),
    mounts: repositoryWithAxios('mounts'),
    mountAdjustments: repositoryWithAxios('mounts', 'adjustments'),
    mountAdjustmentsOrder: repositoryWithAxios('mounts', 'adjustmentsOrder'),
    mountExtras: repositoryWithAxios('mounts', 'extras'),
    mountTypes: repositoryWithAxios('mountTypes'),
    notifications: repositoryWithAxios('notifications'),
    regions: repositoryWithAxios('regions'),
    settings: repositoryWithAxios('settings'),
    tags: repositoryWithAxios('tags'),
    trophyrooms: repositoryWithAxios('trophyrooms'),
    users: repositoryWithAxios('users'),
    userNotifications: repositoryWithAxios('users', 'notifications')
  }

  inject('repositories', repositories)
}
