import Vue from 'vue'
import { resolveSchema } from '@/api/ApiHelpers'

export const state = () => ({
  type: 'sectionSchemas',
  aliasField: 'section_name',
  schemaType: 'sectionSchemas',
  all: {}
})

// Mutations ==================

export const mutations = {
  REPLACE_ALL (state, resource) {
    state.all = resource
  },
  MERGE (state, resource) {
    const index = state.all[resource.section_name]
    if (index === -1) {
      Vue.set(state.all, resource.section_name, resource)
    } else {
      Vue.set(state.all, resource.section_name, resource)
    }
  },
  setFieldMap: (state, payload) => {
    state.fieldMap = payload[0]
  },
  addHeaders (state, headers) {
    Object.assign(state.fieldMap, headers)
  },
  add (state, heading) {
    Vue.set(state.all, heading.field_name, heading)
  },
  addHeader (state, column) {
    const f = {
      text: column.title,
      field: column.field_name,
      type: column.type,
      value: column.field_name,
      align: 'left',
      protected: false,
      sortable: true
    }
    if (column.type === 'dropdown') {
      f.items = column.dropdown
    }

    state.columns.push(f)
  },
  addbinding (state, filter) {
    if (filter.type === 'date') {
      Vue.set(state.bound, filter.field_name, { start: '', end: '' })
    } else {
      Vue.set(state.bound, filter.field_name, '')
    }
  },
  update (state, { id, member }) {
    state.all[id] = member
  },
  loading (state, data) {
    state.isLoading = data
  }
}

// Actions ====================
export const actions = {
  all ({ commit, state, dispatch, rootState }, payload) {
    payload.forEach((item) => {
      commit('MERGE', resolveSchema(item))
      const model = item.section_name
      // console.log(item.section_name)
      if (Object.prototype.hasOwnProperty.call(rootState, model)) {
        // console.log(item.schema.schemas)
        if (Object.prototype.hasOwnProperty.call(item.schema, 'schemas')) {
          const formSchema = item.schema.schemas.find(i => i.key === `${model}_form`)
          dispatch(`${model}/UpdateBound`, formSchema.headers, {
            root: true
          })
        }
      }
    })
  },

  async list ({ commit, state, getters, dispatch }) {
    const params = {}
    const results = await this.$repositories[state.type].index(params)

    // console.log('list response', results)
    if (results) {
      dispatch('all', results.data)
    }
  },
  async update ({ commit, state, getters, dispatch }, { id, payload }) {
    const results = await this.$repositories[state.type].update({
      id,
      payload
    })
    if (Object.prototype.hasOwnProperty.call(results, 'error')) {
      return
    }
    dispatch('all', results.data)
  }
}

// Getters ====================

export const getters = {
  findtablebyname: state => (itemName) => {
    // console.log(itemName)
    let rawTable = []
    if (state.all && Object.values(state.all).length) {
      rawTable = Object.values(state.all[itemName].schema)
      rawTable = rawTable.sort(function (a, b) {
        return a.column - b.column // this will be updated to order in the near future
      })
    }
    return rawTable
  },
  findbyname: state => (itemName) => {
    console.log(itemName)
    const all = state.all
    return all[itemName].schema
  }

}
