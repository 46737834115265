export const state = () => ({
  snack: '',
  snackSettings: {
    color: 'secondary',
    top: false,
    timeout: 4000,
    right: true
  },
  pastView: [],
  loadStatus: 0,
  loadCount: 0,
  validateFields: {},
  showFormErrors: false,
  cachedData: [],
  loadStores: [
    'sectionSchemas',
    'adjustments',
    'animals',
    'animalTypes',
    'announcements',
    'extras',
    'fileGeneral',
    'files',
    'tags',
    'filters',
    'mounts',
    'mountTypes',
    'regions',
    'settings',
    'trophyrooms',
    'users'
  ]
})

export const actions = {
  resetPassword ({ commit, state }, payload) {
    return this.$repositories.forgotPassword.create(payload)
  },
  async navBack ({ commit, state }) {
    const newActive = state.pastView[1]
    await commit('NAVIGATE_ACTIVE_DIALOG_BACK')
    return newActive
  }
}

export const mutations = {
  handle_error (state, snack) {
    state.snack = snack
  },
  set_validateFields: (state, validateFields) => {
    state.validateFields = validateFields
  },
  set_showFormErrors: (state, showFormErrors) => {
    state.showFormErrors = showFormErrors
  },
  handle_errorSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings
  },
  NAVIGATE_ACTIVE_DIALOG_BACK (state) {
    state.pastView.splice(0, 2)
  },
  SET_HISTORY (state, payload) {
    state.pastView.unshift(payload)
  },
  SET_LOADCOUNT (state, payload) {
    state.loadCount = payload
  },
  SET_LOADSTATUS (state, payload) {
    state.loadStatus = payload
  },
  FLUSH_HISTORY (state) {
    state.pastView = []
  },
  SET_DYNAMIC (state, resource) {
    state[resource.key] = resource.value
  }
}
export const getters = {
  permissions (state, commit, rootState) {
    let permissions = []
    permissions = rootState.auth.user.data.can
    return permissions
  },
  roles (state, commit, rootState) {
    let roles = []
    if (rootState.auth.user.data) {
      roles = rootState.auth.user.data.roles
    }
    return roles
  }
}
