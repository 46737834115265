import Vue from 'vue'
import _ from 'lodash'
import VueClipboard from 'vue-clipboard2'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueHtml2pdf from 'vue-html2pdf'

if (!Vue.__my_mixin__) {
  Vue.__my__mixin__ = true
  Vue.mixin({
    methods: {
      showErrorSnack (errorMessage) {
        const snackSettings = {
          color: 'error',
          top: false,
          timeout: 2000,
          right: true
        }
        this.$store.commit('system/set_snackSettings', snackSettings)
        this.$store.commit('system/SET_SNACK', 'ERROR: ' + errorMessage)
      },
      showSuccessSnack (errorMessage) {
        const snackSettings = {
          color: 'secondary',
          top: false,
          timeout: 2000,
          right: true
        }
        this.$store.commit('system/set_snackSettings', snackSettings)
        this.$store.commit('system/SET_SNACK', 'Success: ' + errorMessage)
      },
      isEmptyObject (obj) {
        return JSON.stringify(obj) === '{}'
      },
      cloneObj (obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      objHas (obj, field) {
        if (typeof obj === 'object') {
          return Object.prototype.hasOwnProperty.call(obj, field)
        }
        return false
      },
      objToArray (obj) {
        return Object.values(this.cloneObj(obj))
      },
      objHasAndNotEmpty (obj, field) {
        if (this.objHas(obj, field)) {
          if (obj[field]) {
            return true
          }
        }
        return false
      },
      fullTextSearch (array, value) {
        array.filter(data => JSON.stringify(data).replace(/("\w+":)/g, '').toLowerCase().includes(value.toLowerCase()))
      },
      // filters
      like (array, key, value) {
        return array.filter(data => JSON.stringify(data[key]).replace(/("\w+":)/g, '').toLowerCase().includes(value.toLowerCase()))
      },
      truthy (array, key, value) {
        if (value) {
          return array.filter(data => Boolean(data[key]))
        }
        return array.filter(data => !data[key])
      },
      eq (array, key, value) {
        return array.filter(data => data[key] === value)
      },
      ltFloat (array, key, value) {
        return array.filter(data => parseFloat(data[key]) < parseFloat(value))
      },
      lt (array, key, value) {
        return array.filter(data => data[key] < value)
      },
      lt_eq (array, key, value) {
        return array.filter((data) => {
          return data[key] && data[key] <= value
        })
      },
      gt (array, key, value) {
        return array.filter(data => data[key] > value)
      },
      gt_eq (array, key, value) {
        return array.filter(data => data[key] >= value)
      },
      gtFloat (array, key, value) {
        return array.filter(data => data[key] > parseFloat(value))
      },
      arrHas (array, key, value) {
        if (value === 'none') {
          return array.filter(data => !data[key].length)
        }
        return array.filter(data => data[key].length >= value)
      },
      any (array, key, value) {
        if (value === 'true') {
          return array.filter(data => data[key] !== null)
        }
        return array.filter(data => data[key] === null)
      },
      gt_eq_any (array, key, value) {
        if (value === 'true') {
          return array.filter(data => data[key] !== null)
        }

        return array.filter((data) => {
          return data[key] === null || data[key] >= value
        })
      },
      isIn (array, key, values) {
        if (typeof values === 'string') {
          values = values.split(',').map(v => v.toString().trim())
        }

        return array.filter(data => values.includes(data[key]))
      },
      intersects (array, key, values) {
        if (typeof values === 'string') {
          values = values.split(',').map(v => v.toString().trim())
        }

        return array.filter(data => _.intersection(values, data[key]).length > 0)
      },
      in (array, key, values) {
        if (typeof values === 'string') {
          values = values.split(',').map(v => v.toString().trim())
        }

        return array.filter(data => values.includes(data[key]))
      },
      find (array, key, value) {
        return array.filter((item) => {
          if (!item[key]) {
            return false
          }
          const relationsToFind = value.split(',')

          if (Array.isArray(item[key])) {
            const ids = item[key]
            return ids.some(r => relationsToFind.includes(r.toString()))
          }

          return relationsToFind.includes(item[key].toString())
        })
      },
      isEmpty (value) {
        // If value is null on undefined:
        if (value == null) { return true }

        // By types:
        if (typeof value === 'boolean') { return !value }
        if (typeof value === 'number') { return value === 0 }
        if (typeof value === 'string') { return value === '0' || value.length === 0 }

        // Empty arrays:
        if (Array.isArray(value)) { return value.length === 0 }

        // Empty objects:
        if (value.toString === Object.prototype.toString) {
          switch (value.toString()) {
            case '[object File]':
            case '[object Map]':
            case '[object Set]': {
              return value.size === 0
            }
            case '[object Object]': {
              for (const key in value) {
                if (Object.prototype.hasOwnProperty.call(value, key)) { return false }
              }

              return true
            }
          }
        }

        // Everything else...
        return false
      }
    }
  })
}

VueClipboard.config.autoSetContainer = true
Vue.use(VueExcelXlsx)
Vue.use(VueHtml2pdf)
Vue.use(_)
Vue.use(VueClipboard)
