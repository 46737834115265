import _ from 'lodash'

export function resolveRelations (data, fields, rootState) {
  const results = {}
  fields.forEach((field) => {
    const valuefield = field.storename ? field.storename : field.value

    results[field.name] = []
    if (Array.isArray(data[field.name])) {
      results[field.name] = data[field.name].map(
        x => rootState[valuefield].all[x]
      )
    } else {
      results[field.name] = rootState[valuefield].all[data[field.name]]
    }
  })
  return { ...data, ...results }
}

export function resolveSchema (data) {
  const results = {}
  // let schema = JSON.parse(data.schema);
  results.id = data.id
  results.section_name = data.section_name
  results.schema = data.schema
  return results
}

export function resolveNotifications (data) {
  const results = {}
  // let schema = JSON.parse(data.schema);
  results.id = data.id
  results.type = data.type
  results.schema = data.data
  return results
}

export function titleCase (str) {
  return str.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
}

export class FilterResolver {
  // region Common
  constructor (page, schemas, currentQuery, store) {
    this.page = page
    this.schemas = schemas
    this.resolved = {}
    this.currentQuery = currentQuery
    this.store = store
    this.relations = store.state[page.path].relations
  }

  static filterMethods = {
    like (array, key, value) {
      return array.filter(data => JSON.stringify(data[key]).replace(/("\w+":)/g, '').toLowerCase().includes(value.toLowerCase()))
    },
    truthy (array, key, value) {
      if (value) {
        return array.filter(data => Boolean(data[key]))
      }
      return array.filter(data => !data[key])
    },
    eq (array, key, value) {
      return array.filter(data => data[key] === value)
    },
    ltFloat (array, key, value) {
      return array.filter(data => parseFloat(data[key]) < parseFloat(value))
    },
    lt (array, key, value) {
      return array.filter(data => data[key] < value)
    },
    lt_eq (array, key, value) {
      return array.filter((data) => {
        return data[key] && data[key] <= value
      })
    },
    gt (array, key, value) {
      return array.filter(data => data[key] > value)
    },
    gt_eq (array, key, value) {
      return array.filter(data => data[key] >= value)
    },
    gtFloat (array, key, value) {
      return array.filter(data => data[key] > parseFloat(value))
    },
    arrHas (array, key, value) {
      if (value === 'none') {
        return array.filter(data => !data[key].length)
      }
      return array.filter(data => data[key].length >= value)
    },
    any (array, key, value) {
      if (value === 'true') {
        return array.filter(data => data[key] !== null)
      }
      return array.filter(data => data[key] === null)
    },
    gt_eq_any (array, key, value) {
      if (value === 'true') {
        return array.filter(data => data[key] !== null)
      }

      return array.filter((data) => {
        return data[key] === null || data[key] >= value
      })
    },
    isIn (array, key, values) {
      if (typeof values === 'string') {
        values = values.split(',').map(v => v.toString().trim())
      }

      return array.filter(data => values.includes(data[key]))
    },
    intersects (array, key, values) {
      if (typeof values === 'string') {
        values = values.split(',').map(v => v.toString().trim())
      }

      return array.filter(data => _.intersection(values, data[key]).length > 0)
    },
    in (array, key, values) {
      if (typeof values === 'string') {
        values = values.split(',').map(v => v.toString().trim())
      }

      return array.filter(data => values.includes(data[key]))
    },
    find (array, key, value) {
      return array.filter((item) => {
        if (!item[key]) {
          return false
        }
        const relationsToFind = value.split(',')

        if (Array.isArray(item[key])) {
          const ids = item[key]
          return ids.some(r => relationsToFind.includes(r.toString()))
        }

        return relationsToFind.includes(item[key].toString())
      })
    }
  }

  static filterDictionary = {
    like: 'contains text',
    truthy: 'is',
    eq: 'equals',
    ltFloat: 'is less than',
    lt: 'is less than',
    lt_eq: 'is max.',
    gt: 'is greater than',
    gt_eq: 'is min. ',
    gtFloat: 'is greater than',
    arrHas: value => (value === 'none' ? 'count is' : ' count is min.'),
    any: value => (value === 'true' ? 'is not null' : 'is null'),
    gt_eq_any: value => (value === 'true' ? 'is not null' : 'is null or more than'),
    isIn: 'contains',
    in: 'contains',
    notIn: 'not in',
    isEmpty: 'is empty',
    isNotEmpty: 'is not empty',
    find: 'is one of'
  }

  static hrValueByfilterDictionary = {
    like: (value, hrValue) => hrValue,
    truthy: (value, hrValue) => (value ? 'Yes/Filled/True/Positive' : 'No/Empty/False/Negative'),
    eq: (value, hrValue) => hrValue,
    ltFloat: (value, hrValue) => hrValue,
    lt: (value, hrValue) => hrValue,
    lt_eq: (value, hrValue) => hrValue,
    gt: (value, hrValue) => hrValue,
    gt_eq: (value, hrValue) => hrValue,
    gtFloat: (value, hrValue) => hrValue,
    arrHas: (value, hrValue) => (value === 'none' ? '0' : hrValue),
    any: (value, hrValue) => '',
    gt_eq_any: (value, hrValue) => (value === 'true' ? '' : hrValue),
    isIn: (value, hrValue) => hrValue,
    in: (value, hrValue) => hrValue,
    notIn: (value, hrValue) => hrValue,
    isEmpty: (value, hrValue) => hrValue,
    isNotEmpty: (value, hrValue) => hrValue,
    find: (value, hrValue) => hrValue
  }
  // endregion

  // region Main methods
  resolve () {
    const bucket = {}
    const regex = /([^(\n\r\s]+)\(([^)]+)?\)/g

    /**
     * Format: <relation?.relation?.property> => <filter>(<value>)
     *
     * Main lines (may not be in actual order comparing to the code):
     * - pick the filter and value
     * - pick the relations & property
     * - translate relation names
     * - translate property name
     * - translate value
     * - translate filter
     * Notes:
     * - "property" and "field" is alias to each other
     * - detected property may actually correspond to a relation (of the main state or the last state in the relations)
     * - translation of a filter may change according to value
     * - translation of the displayed value may change according to the filter
     * Before / after:
     * - example statement is producesFu.producer.name=like(eray)
     * -----------------------------------------------------------
     * | Relations             | Property   | Filter    | Value  |
     * -----------------------------------------------------------
     * | producesFu, producer  | name       | like      | eray   |  --> resolved
     * -----------------------------------------------------------
     * | Farmunit > Producer > | Name       | contains  | 'eray' |  --> displayed
     * -----------------------------------------------------------
     */

    // loop for every item in the query/activeFilter
    for (const field in this.currentQuery) {
      // the line below picks every filter&value pair in a string (there will be only one actually)
      // say the statement is find(355). resolveCondition will be [["find(355)", "find", "355"]]
      const resolveCondition = [...this.currentQuery[field].matchAll(regex)]

      if (resolveCondition.length) {
        const [statement, filter, value] = resolveCondition[0]
        // split the field by dots. If the count of the resulting array is one, it doesn't belong to a related model.
        // If it is more than one, it does
        const splitField = field.split('.')

        const relationChain = {}
        // it is a related field or not, the last one is the final property/relation name
        const property = splitField.pop()

        let lastStore = this.page.path

        // if there are remnants in the array after we popped the property out, they are relations
        for (const splitFieldKey in splitField) {
          // getting the storename from the relation objects by respecting the parent & child,
          // we get the correct relation names
          const rel = this.findRelation(lastStore, splitField[splitFieldKey])
          lastStore = rel.storename
          // it is important that we place them in a new object in order the relations chained
          relationChain[splitFieldKey] = rel
        }
        // hr -> Human Readable
        // set it to default first
        let hrField = titleCase(property)
        // we need to detect if the property is eventually a relation or not
        let topStore = this.page.path
        // just to get relationChain length:
        const relKeys = Object.keys(relationChain)
        if (relKeys.length) {
          const lastChain = relationChain[relKeys.length - 1]
          topStore = lastChain.storename
        }
        const propertyRelation = this.findRelation(topStore, property)
        if (propertyRelation) {
          hrField = propertyRelation.label
        }

        let hrObject = ''
        const relationLabels = []
        // collect names of the relations
        for (const relationChainKey in relationChain) {
          relationLabels.push(relationChain[relationChainKey].compactLabel ?? relationChain[relationChainKey].label)
        }
        // Mount > Animal  !! only relation names merged, property name needs to be processed more !!
        hrObject = relationLabels.join(' > ')

        // get a human-readable form of the filter ( "find" => "is one of", "like" => "contains")
        // some filters will have different meanings according to the value
        // like the differences between truthy(1) and truthy(), arrHas(none) and arrHas(5), any(true) and any()
        // or like gt_eq_any
        const hrFilter = this.getFilterHr(filter, value)

        // here while deriving a displayable format from the value
        // find(<ids>) has a specific case as we need to collect displayable names from models
        // belonging to <ids> sent to "find" filter
        const foundById = property === 'id' && filter === 'find'

        let hrValue = null
        let aliases = []

        if (foundById) {
          // the top parent is this.path
          // if we have the relations, the last one in the order is the one these ids belong to
          // otherwise the ids belong to the store of the page
          let parentStore = this.page.path
          const ids = value.split(',').map(id => parseInt(id))

          // just to get relationChain length:
          const relKeys = Object.keys(relationChain)
          if (relKeys.length) {
            const lastChain = relationChain[relKeys.length - 1]
            // we detected the store name where the ids belong to
            parentStore = lastChain.storename
          }

          // alias field of a state is the NAME OF THE FIELD to be used as a title/name:
          const aliasField = this.store.state[parentStore].aliasField
          // get the objects which the ids represent:
          const foundObjects = Object.values(this.store.state[parentStore].all).filter(r => ids.includes(r.id))
          // collect their titles/names:
          aliases = foundObjects.map(item => item[aliasField])
          // flatten them - they are ready to display in a nice format:
          hrValue = aliases.join(', ')
        } else if (value !== undefined && value !== '' && value !== null) {
          hrValue = value.toString()
        }

        // some values must be nullified or changed according to the filter they are sent to:
        hrValue = this.adjustValueHrByFilter(filter, value, hrValue)

        // collect all the resolved results
        bucket[field] = {
          statement,
          filter,
          value,
          relationChain,
          relationLabels,
          property,
          hrObject,
          hrField,
          hrValue,
          aliases,
          hrFilter,
          foundById
        }
      }
    }

    this.resolved = bucket // { desc: 'Applied filters will be resolved into this object' }

    return this
  }

  filter (items) {
    let filtered = items
    if (filtered.length > 0) {
      for (const [key, value] of Object.entries(this.currentQuery)) {
        if (value) {
          // determine if the key is a relation
          if (key.includes('.')) {
            // must be a relation
            filtered = this.handleRelations(filtered, key, value)
          } else {
            // key is not a relation, it must be an attribute
            const {
              filterOperator,
              filterValue
            } =
              this.parseFilterValue(value)
            filtered = FilterResolver.filterMethods[filterOperator](filtered, key, filterValue)
          }
        }
      }
    }

    return filtered // filter the given items and return the filtered
  }
  // endregion

  // region Filter helpers
  handleRelations (allFiltered, key, value) {
    const parsedRelation = this.sliceString(key, '.')

    const relation = this.findRelation(this.page.path, parsedRelation.first)

    let filteredRelationIds = []
    if (parsedRelation.second.includes('.')) {
      const parsedNestedRelation = this.sliceString(
        parsedRelation.second,
        '.'
      )

      const nestedRelation = this.findRelation(
        relation.value,
        parsedNestedRelation.first
      )

      const nestedRelationId = this.applyRelationFilter(
        nestedRelation.value,
        parsedNestedRelation.second,
        value
      )

      const allFromRelationModel = Object.values(
        this.$store.state[relation.value].all
      )

      filteredRelationIds = allFromRelationModel
        .filter(function (data) {
          if (Array.isArray(data[parsedNestedRelation.first])) {
            return data[parsedNestedRelation.first].some(item =>
              nestedRelationId.includes(item)
            )
          } else {
            return [data[parsedNestedRelation.first]].some(item =>
              nestedRelationId.includes(item)
            )
          }
        })
        .map(r => r.id)
    } else {
      filteredRelationIds = this.applyRelationFilter(
        relation.storename,
        parsedRelation.second,
        value
      )
    }

    return allFiltered.filter(function (data) {
      if (Array.isArray(data[parsedRelation.first])) {
        return data[parsedRelation.first].some(item =>
          filteredRelationIds.includes(item.id)
        )
      } else {
        return [data[parsedRelation.first]].some(item =>
          filteredRelationIds.includes(item.id)
        )
      }
    })
  }

  sliceString (string, operator) {
    const first = string.slice(0, string.indexOf(operator))
    const second = string.slice(string.indexOf(operator) + 1)
    return {
      first,
      second
    }
  }

  findRelation (path, key) {
    return this.relations.find(r => r.name === key)
  }

  parseFilterValue (value) {
    // the filter operator will appear before the first (
    // Example ?flow=lt(24)   operator would be "lt"
    const filterOperator = value.slice(0, value.lastIndexOf('('))
    // filter value is found between the parentheses
    // Example ?flow=lt(24)   filter value would be 24
    const filterValue = value.slice(
      value.indexOf('(') + 1,
      value.lastIndexOf(')')
    )
    return {
      filterOperator,
      filterValue
    }
  }

  applyRelationFilter (model, key, value) {
    const {
      filterOperator,
      filterValue
    } = this.parseFilterValue(value)
    const allFromRelationModel = Object.values(
      this.store.getters[`${model}/standardized`]
    )
    return FilterResolver.filterMethods[filterOperator](allFromRelationModel, key, filterValue).map(
      r => r.id
    )
  }
  // endregion

  // region Resolve helpers
  getFilterHr (filter, value) {
    const item = FilterResolver.filterDictionary[filter] ?? null
    if (item === null) {
      return filter
    }
    return typeof item === 'function' ? item(value) : item
  }

  adjustValueHrByFilter (filter, value, hrValue) {
    const item = FilterResolver.hrValueByfilterDictionary[filter] ?? null
    if (item === null) {
      return hrValue
    }
    return typeof item === 'function' ? item(value, hrValue) : item
  }
  // endregion
}
