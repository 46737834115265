
export default {
  data () {
    return {
      initialLoadCompleted: false,
      clipped: true,
      drawer: true,
      syncing: false,
      searchValue: 'SearchSystemWide',
      menuItems: [
        /* {
          icon: 'mdi-home',
          key: 'home',
          title: 'Dashboard',
          to: '/'
        },
        {
          icon: 'mdi-panorama-horizontal-outline',
          key: 'mounts',
          title: 'Mounts',
          to: '/mountbuilder/mounts'
        } */
        {
          icon: 'mdi-image-multiple',
          key: 'photos',
          title: 'Photo Library',
          to: '/photos'
        },
        {
          icon: 'mdi-view-gallery-outline',
          key: 'trophyrooms',
          title: 'Trophy Room Galleries',
          to: '/trophyrooms'
        },
        {
          icon: 'mdi-image',
          title: 'Taxidermy Galleries',
          to: '/mountbuilder/regions'
        }/*,
        {
          icon: 'mdi-paw',
          title: 'Species Types',
          to: '/mountbuilder/animalTypes'
        },
        {
          icon: 'mdi-panorama-horizontal-outline',
          title: 'Mount Types',
          to: '/mountbuilder/mountTypes'
        } */
        /* {
          icon: 'mdi-barley',
          key: 'extras',
          title: 'Mount Add-ons',
          to: '/mountbuilder/extras'
        } */
      ],
      settings: [
        {
          icon: 'mdi-map',
          title: 'Regions',
          to: '/mountbuilder/regions'
        },
        {
          icon: 'mdi-paw',
          title: 'Species Types',
          to: '/mountbuilder/animalTypes'
        },
        {
          icon: 'mdi-panorama-horizontal-outline',
          title: 'Base Price List',
          to: '/mountbuilder/mountTypes'
        }
      ],
      adminsettings: [
        {
          icon: 'mdi-map',
          title: 'Regions',
          to: '/mountbuilder/regions'
        },
        {
          icon: 'mdi-paw',
          title: 'Animal Types',
          to: '/mountbuilder/animalTypes'
        },
        {
          icon: 'mdi-panorama-horizontal-outline',
          title: 'Mount Types',
          to: '/mountbuilder/mountTypes'
        },
        {
          icon: 'mdi-barley',
          title: 'Mount Add-ons',
          to: '/mountbuilder/extras'
        }
        // {
        //   icon: 'mdi-flag',
        //   title: 'Flags',
        //   to: '/mountbuilder/flags'
        // },
        // {
        //   icon: 'mdi-pound',
        //   title: 'Tags',
        //   to: '/mountbuilder/flags'
        // }
      ],
      notifications: [],
      miniVariant: false
    }
  },
  computed: {
    storeState () {
      return this.$store.state
    },
    loadStores () {
      return this.$store.state.system.loadStores
    },
    roles () {
      return this.$store.getters['system/roles']
    },
    is_admin () {
      return this.roles.includes('admin') ||
        this.roles.includes('superAdmin') ||
        this.roles.includes('super admin')
    }
  },
  async created () {
    await this.hydrateStores()
    this.initialLoadCompleted = true
  },
  methods: {
    async hydrateStores () {
      this.syncing = true

      this.$nextTick(() => {
        this.$nuxt.$loading.start()
      })
      if (this.$nuxt.isOffline) {
        await this.getLocalForageToState()
      }
      await this.fetchData()
      await this.saveStateToLocalForage()
      this.$nuxt.$loading.finish()

      this.syncing = false
    },
    async getLocalForageToState () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.objHas(value, 'all')) {
          const all = await this.$localForage.getItem(key)
          if (all) {
            await this.$store.commit(`${key}/REPLACE_ALL`, all)
          }
        }
      }
    },
    async fetchData () {
      for (const key of this.loadStores) {
        await this.$store.dispatch(`${key}/list`)
      }
    },
    async saveStateToLocalForage () {
      for (const [key, value] of Object.entries(this.storeState)) {
        if (this.objHas(value, 'all')) {
          await this.$localForage.setItem(key, value.all)
        }
      }
    },
    logout () {
      this.$auth.logout().then(() => this.$router.push('/login'))
    }
  }
}
